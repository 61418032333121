<template>
	<div class="label_photo">
		<v-progress-circular v-if="changeAvatarLoader" :size="160" :width="3" style="z-index: 1;" color="#20C4F5" indeterminate></v-progress-circular>

		<div v-if="isLoading || !avatar.loaded" class="skeleton-loader"></div>

		<v-img :src="`${avatar.small}?cache=${avatar.key}`"
           v-bind:srcset="avatar.srcset ? `${avatar.small}?cache=${avatar.key} 1x, ${avatar.srcset}?cache=${avatar.key} 2x` : null"
           loading="lazy"
           @load="avatarLoaded"
           :class="{'default-image': isImageDefault, 'show': avatar.loaded, 'hidden': isLoading || !avatar.loaded}"
           @click="$refs['modal-image'].show()" alt="Label profile avatar">
		</v-img>
		<button v-ripple v-if="isOwner" type="button" class="change_ava" @click="changeAva">
			<span class="material-icons">photo_camera</span>
		</button>

		<input type="file" hidden ref="uploadedFile" accept="image/png, image/jpeg" @change="photoUpload">
		<v-dialog
			v-model="clipperDialog"
			width="500">
			<div class="photo_clipper">
				<button class="close_modal" @click="clipperDialog = false"></button>
				<clipper-fixed
					preview="avatarPreview"
					ref="clipper"
					:ratio="1"
					class="basic lg clipper-fixed"
					:src="clipperAvatar"
					bg-color="transparent"
					:grid="false"
					shadow="rgba(0,0,0,0.8)"
					:area="50"
					:handle-zoom-event="handleZoom"
					@load="imgAvaLoad"
					:rotate="0">
					<div slot="placeholder">No image</div>
				</clipper-fixed>

				<clipper-range v-model="scale" :min="0.5" :max="2"></clipper-range>
				<div class="actions_clipper_photo">
					<button type="button" class="tetriatary_btn small close_clipper_photo" @click="closeClipperDialog" v-ripple>Close</button>

					<!-- Upload New Photo -->
					<!--                    <input type="file" hidden ref="uploadedFile" accept="image/png, image/jpeg" @change="photoUpload">-->
					<button type="button" class="clip_photo primary_btn small" @click="clipImage" v-ripple>Save</button>
				</div>
			</div>
		</v-dialog>

		<modalImage ref="modal-image" :image="`${avatar.original}?cache=${avatar.key}`"/>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {baseUrlToBlob} from "@/utils/baseUrlToBlob";

const ModalImage = () => import("@/components/public/modalImage");

export default {
	name: "recordLabelProfileAvatar",
	components: {
		ModalImage
	},
	props: {
		isOwner: {
			type: Boolean,
			required: true,
		},
		labelID: {
			required: true
		},
		isLoading: Boolean,
	},
	data() {
		return {
			apiUrl: process.env.VUE_APP_API_URL,

			isImageDefault: false,
			avatar: {
				original: '',
				small: '',
        srcset: '',
				loaded: false,
				key: new Date().getTime(),
			},
			uploadFile: '',
			clipperAvatar: '',
			changeAvatarLoader: false,

			clipperDialog: false,
			scale: 0.5,
		}
	},
	watch: {
		scale(val) {
			this.$refs.clipper.setWH$.next(+val);
		},
		RECORD_LABEL: {
			handler() {
				if (this.RECORD_LABEL && this.RECORD_LABEL.logo) {
					this.getAvatar();
				}
			},
			deep: true
		}
	},
	computed: {
		...mapGetters(['FILE_URL', 'RECORD_LABEL']),

	},
	created() {
		this.getAvatar();
	},
	mounted() {

	},
	methods: {
		...mapActions(['ADD_FILE', 'CHANGE_RECORD_LABEL', 'GET_RECORD_LABEL']),
		getAvatar() {
			this.changeAvatarLoader = true;
			this.avatar.loaded = false;
			
			this.avatar.small = require('@/assets/image/svg/square_avatar.svg');
			this.avatar.original = require('@/assets/image/svg/default_avatar.svg');
			
			if (this.RECORD_LABEL?.logo?.thumbnail?.['160x160']) {
				this.avatar.small = this.RECORD_LABEL.logo.thumbnail['160x160'];
				this.avatar.srcset = this.RECORD_LABEL.logo.thumbnail['160x160@2x'];
				this.avatar.original = this.RECORD_LABEL.logo.thumbnail['1080x1080'];
				this.isImageDefault = false;
			} else {
				this.isImageDefault = true;
			}

			this.avatar.loaded = true;
			this.changeAvatarLoader = false;
			this.avatar.key = new Date().getTime();
		},
		avatarLoaded() {
			this.avatar.loaded = true;
		},
		imgAvaLoad() {
			this.scale = 0.5;
			this.$refs.clipper.setWH$.next(0.5);
		},
		handleZoom(scale) {
			if (!isNaN(scale)) {
				const limitedScale = Math.max(Math.min(2, scale), 0.1);
				this.scale = limitedScale;
				return limitedScale;
			}
		},

		photoUpload() {
			this.uploadedFile = this.$refs.uploadedFile.files[0];
			this.clipperDialog = true;
			this.scale = 0.5;

			const fileReader = new FileReader();
			fileReader.addEventListener('load', () => {
				this.clipperAvatar = fileReader.result;
			});
			fileReader.readAsDataURL(this.uploadedFile);
		},
		changeAva() {
			this.$refs.uploadedFile.click();
			if (this.uploadedFile) {
				this.clipperDialog = true;
			}
		},

		clipImage() {
			this.changeAvatarLoader = true;

			const canvas = this.$refs.clipper.clip();
			let dataURL = canvas.toDataURL("image/png", 1);
			this.uploadedFile = baseUrlToBlob(dataURL);

			this.clipperDialog = false;

			const labelData = {
				id: parseInt(this.labelID)
			}

			let formData = new FormData();
			formData.append('file', this.uploadedFile);
			formData.append('type', 'record-label');
			formData.append('entity_id', parseInt(labelData.id));

			this.ADD_FILE(formData)
				.then(() => {
					labelData.logo = this.FILE_URL;
				})
				.catch(err => console.log(`ADD_FILE (label), ${err}`))
				.finally(() => {
					if (labelData.logo) {
						this.CHANGE_RECORD_LABEL(labelData)
							.then(() => {
								this.GET_RECORD_LABEL(this.labelID)
									.then(() => {
										const newCacheKey = new Date().getTime();
										this.avatar.key = newCacheKey;
										this.$emit('image-updated', newCacheKey);
									})
									.catch(err => console.log(`get record label, ${err}`));
							})
							.catch(err => console.log(`CHANGE_LABEL_DATA, ${err}`));
					}
					this.changeAvatarLoader = false;
				});
		},
		closeClipperDialog() {
			this.clipperDialog = false;
		},
		refreshAvatar(newCacheKey) {
			this.avatar.key = newCacheKey;
			this.getAvatar();
		}
	}
}
</script>

<style scoped>
.label_photo {
	position: relative;
	width: 160px;
	height: 160px;
}

.v-image {
	width: 160px !important;
	height: 160px !important;
	border-radius: 50%;
	overflow: hidden;
}

.v-image :deep(.v-image__image) {
	border-radius: 50%;
}

.v-image.default-image :deep(.v-image__image) {
	background-color: #10182b !important;
	background-size: 90% !important;
	background-repeat: no-repeat !important;
	border-radius: 50% !important;
}

.v-image.default-image {
	background: #10182b !important;
}

.v-image.show {
	opacity: 1;
}

.skeleton-loader {
	width: 160px;
	height: 160px;
	border-radius: 50%;
	background: linear-gradient(90deg, #1a2235 25%, #243049 50%, #1a2235 75%);
	background-size: 200% 100%;
	animation: loading 1.5s infinite;
}

.hidden {
	display: none;
}

@keyframes loading {
	0% {
		background-position: 200% 0;
	}
	100% {
		background-position: -200% 0;
	}
}
</style>
